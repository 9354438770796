<template>
    <transition name="fade">
      <div class="card" style="width: 100%;" v-if="!state.loaderEnabled">
        <div class="card-header border-0 pt-6" style="display: flex; justify-content: space-between; align-items: stretch; flex-wrap: nowrap; min-height: 70px; padding: 0 2.25rem; background-color: transparent; flex-direction: row;">
          <el-divider content-position="left"><h2>Administrer les utilisateurs</h2></el-divider>
          <button class="btn btn-primary mx-2" style="width: 220px;" @click="addEdit_user({'us_seq': -1})">Ajouter un utilisateur</button>        
        </div>
        <div class="card-body pt-0">
            
      
          <Datatable
            v-if="state.myList && state.myList.length > 0"
            :table-data="state.myList"
            :table-header="tableHeader"
            :enable-items-per-page-dropdown="true"
            :rows-per-page="50"
            empty-table-text="Aucune ligne à afficher."
            :key="state.loadingDatatable"
            class="mt-4 table-small-td cursor-pointer"
            @click-function="addEdit_user($event)"
          >

            <template v-slot:cell-us_seq="{ row: data }">
              <div class="mx-2 text-gray-900">
                {{ data.us_seq }}
              </div>
            </template>

            <template v-slot:cell-us_prenom="{ row: data }">
              <div  class="fs-5 text-gray-700">{{ data.us_prenom }} {{ data.us_nom }}</div>
            </template>
            
            <template v-slot:cell-us_username="{ row: data }">
              <div  class="fs-5 text-gray-900">{{ data.us_username }}</div>
            </template>

            <template v-slot:cell-us_role="{ row: data }">
              <el-tag size="large" class="mx-1" effect="plain" >{{ data.us_role == "ADM" ? "Administrateur" : "Utilisateur"}}</el-tag>
            </template>

          </Datatable>


        </div>
      </div>
    </transition>
    <el-drawer direction='rtl' v-model="state.drawer" :with-header="false" size="800px" modal-class="modal-transp">
      <div class="card shadow-none" style="width: 100%; height: 100%;">
        <div class="card-header" id="kt_activities_header" style="background: white;">
          
          <div class="page-title d-flex  align-items-center  flex-wrap mb-5 mb-lg-0 " style="justify-content: space-between; width: 100%;">
            <button @click="state.drawer = false" class="btn btn-light"><i class="fas fa-arrow-left fs-4 me-2" ></i> {{$t("Retour")}}</button>
            <h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
              {{ state.typeEddEdit }}
            </h1>
            <button @click="applyChange()" class="btn btn-primary">Appliquer<i class="fas fa-check fs-4 ms-2" ></i></button>
          </div>
        </div>
        <div class="card-body position-relative" style="overflow: auto !important;  height: calc(100vh - 100px); background-size: cover;">


          <div class="card p-10" style="width: 100%; height: 100%;">

            <el-divider content-position="left"><h2>Informations principales</h2></el-divider>
            <br>

            <div class="row mb-6">
              <label class="col-lg-3 col-form-label required fw-bold fs-6">Prénom</label>
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-lg-12 fv-row">
                    <el-input type="text" class="mb-3 mb-lg-0" placeholder="Prénom" v-model="state.form.us_prenom" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-6">
              <label class="col-lg-3 col-form-label required fw-bold fs-6">Nom</label>
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-lg-12 fv-row">
                    <el-input type="text" class="mb-3 mb-lg-0" placeholder="Nom" v-model="state.form.us_nom" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-6">
              <label class="col-lg-3 col-form-label required fw-bold fs-6">Rôle utilisateur</label>
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-lg-12 fv-row">

                    <el-select :disabled="state.form.us_seq == 1 ? true : false" v-model="state.form.us_role" default-first-option placeholder="Veuillez choisir un rôle" style='float: left; width: 100%'>
                        <el-option label="Utilisateur standard" value="USR" />
                        <el-option label="Administrateur" value="ADM" />
                    </el-select>

                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-6" v-loading="state.loaderEnabledTest">
              <label class="col-lg-3 col-form-label fw-bold fs-6">Votre Clé API Meraki</label>
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-lg-8 fv-row">
                    <el-input type="text" class="mb-3 mb-lg-0" placeholder="Saisir votre clé API Meraki" v-model="state.form.us_api_key_meraki" />
                  </div>
                  <div class="col-lg-4 fv-row">
                    <button type="button" class="btn btn-light-primary w-100"  @click="test_api()">
                      <span class="indicator-label"> Tester la clé API </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <br>
            <el-divider content-position="left"><h2>Informations de connexion</h2></el-divider>
            <br>
            <div class="row mb-6">
              <label class="col-lg-3 col-form-label required fw-bold fs-6">Mail de connexion</label>
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-lg-12 fv-row">
                    <el-input type="text" class="mb-3 mb-lg-0" id="IdentConnexion" name="IdentConnexion" placeholder="Mail de connexion" v-model="state.form.us_username" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-6">
              <label class="col-lg-3 col-form-label  fw-bold fs-6" :class="{ 'required':state.form.us_seq == -1 }">Nouveau Mot de passe</label>
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-lg-12 fv-row">
                    <el-input type="password" id="newPass" class="mb-3 mb-lg-0" placeholder="Saisir le nouveau Mot de passe" v-model="state.form.new_password" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-6">
              <label class="col-lg-3 col-form-label fw-bold fs-6" :class="{ 'required':state.form.us_seq == -1 }">Répéter le mot de passe</label>
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-lg-12 fv-row">
                    <el-input type="password" id="renewPass"  class="mb-3 mb-lg-0" placeholder="Répéter le nouveau Mot de passe" v-model="state.form.new_repassword" />
                  </div>
                </div>
              </div>
            </div>

            <div v-if="state.form.us_seq > 1">
              <br>
              <el-divider content-position="left"><h2>Suppression de compte</h2></el-divider>
              <br>
              <el-popconfirm title="Confirmez-vous la suppression du compte utilisateur ?" 
                confirm-button-text="Oui"
                cancel-button-text="Non"
                icon-color="primary"
                @confirm="delUser()"
              >
                <template #reference>
                  <button class="btn btn-danger">Supprimer le compte utilisateur<i class="fas fa-drash fs-4 ms-2" ></i></button>
                </template>
              </el-popconfirm>
              
            </div>

          </div>



        </div>
      </div>
    </el-drawer>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted, reactive } from "vue";
  import { useStore } from "vuex";
  import Datatable from "@/components/kt-datatable/KTDatatable.vue";
  import { ElNotification } from 'element-plus'

  import mAxiosApi from "@/api";

  export default defineComponent({
    name: "Overview",
    components: {Datatable},
    setup() {

    const store = useStore();
    const state = reactive({
        networks : [] as any,
        options: [] as any,
        valueOrga : 0,
        loaderEnabled: true,
        myList: [] as any,
        initialMyList: [] as any,
        loadingDatatable: 0,
        drawer : false,
        currentNetwork: "",
        form : {} as any,
        loaderEnabledTest : false,
        typeEddEdit : "",


    });

    const tableHeader = ref([
      { name: "ID", key: "us_seq", sortable: false},  
      { name: "Prénom Nom", key: "us_prenom", sortable: false},
      { name: "Utilisateur", key: "us_username", sortable: false,},
      { name: "Role", key: "us_role", sortable: false,},
      { name: "", key: "us_test", sortable: false,},
    ]);

      onMounted(async () => {  
        let nUsers = await getAxios("/users");
        state.myList = nUsers
        state.initialMyList = state.myList;
       
        state.loaderEnabled = false;

      });

      const addEdit_user = (data) => {
        if(data.us_seq == -1){
          state.typeEddEdit = "Ajout d'un utilisateur";

          state.form.us_seq = -1;
          state.form.us_prenom = "";
          state.form.us_nom = "";
          state.form.us_username = "";
          state.form.us_api_key_meraki = "";
          state.form.us_role = "USR";
          state.form.new_password = "";
          state.form.new_repassword = "";

        } else {
          state.typeEddEdit = "Modification d'un utilisateur";

          state.form.us_seq = data.us_seq;
          state.form.us_prenom = data.us_prenom;
          state.form.us_nom = data.us_nom;
          state.form.us_username = data.us_username;
          state.form.us_api_key_meraki = data.us_api_key_meraki;
          state.form.us_role = data.us_role;
          state.form.new_password = "";
          state.form.new_repassword = "";

        }
        state.drawer = true;
      };

      const test_api = async () => {
        if(!state.form.us_api_key_meraki) {
          ElNotification({
            title: 'Erreur',
            message: 'Veuillez saisir une clé Meraki, svp',
            type: 'error',
          })
          return false;
        }       
        state.loaderEnabledTest = true;
        const mOrga = await getAxios("/testapi?key="+state.form.us_api_key_meraki);
        state.loaderEnabledTest = false;
        if(mOrga.errors) {
          ElNotification({
            title: 'Erreur',
            message: 'Il semble y avoir une erreur avec la clé API saisie, veuillez la vérifier, svp',
            type: 'error',
          })
        } else {
          ElNotification({ title: 'Succés', message: 'La clé saisie semble fonctionnelle !', type: 'success',})
        }
      }

      const delUser = async () => {
        const form = JSON.stringify(state.form);
        await mAxiosApi.post("/delUser", form, { headers: {   'Content-Type': 'application/json'  }  });
        
        let nUsers = await getAxios("/users");
        state.myList = nUsers
        state.initialMyList = state.myList;
        state.loadingDatatable = state.loadingDatatable + 1;
        state.drawer = false;

      }

      const applyChange = async () => {

        if (state.form.us_seq == -1 && !state.form.new_password) {
          ElNotification({ title: 'Error', message: "Lors de la création d'un compte, le mot de passe doit être stipulé", type: 'error',})
          return false;
        }

        if (state.form.new_password && state.form.new_password != state.form.new_repassword) {
          ElNotification({ title: 'Error', message: "Les mots de passe saisis sont différents", type: 'error',})
          return false;
        }

        if (!state.form.us_username || !state.form.us_prenom || !state.form.us_nom){
          ElNotification({ title: 'Error', message: 'Un des champs obligatoire n\'est pas saisi', type: 'error',})
          return false;
        } 

        const regexMail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!regexMail.test(state.form.us_username)){
          ElNotification({ title: 'Error', message: 'Le mail de connexion doit être un mail valide', type: 'error',})
          return false;
        }

        const form = JSON.stringify(state.form);
        await mAxiosApi.post("/addEditUser", form, { headers: {   'Content-Type': 'application/json'  }  });
        ElNotification({ title: 'Succés', message: 'Changements effectués', type: 'success',})

        let nUsers = await getAxios("/users");
        state.myList = nUsers
        state.initialMyList = state.myList;

        state.loadingDatatable = state.loadingDatatable + 1;

        state.drawer = false
      }
  
      return {
        state,
        store,
        tableHeader,
        addEdit_user,
        test_api,
        applyChange,
        delUser
      };
    },
  });
  
  export const getAxios = async (rr) => { const re = await mAxiosApi.get(rr); return re.data; };

  </script>