
  import { defineComponent, ref, onMounted, reactive } from "vue";
  import { useStore } from "vuex";
  import Datatable from "@/components/kt-datatable/KTDatatable.vue";
  import { ElNotification } from 'element-plus'

  import mAxiosApi from "@/api";

  export default defineComponent({
    name: "Overview",
    components: {Datatable},
    setup() {

    const store = useStore();
    const state = reactive({
        networks : [] as any,
        options: [] as any,
        valueOrga : 0,
        loaderEnabled: true,
        myList: [] as any,
        initialMyList: [] as any,
        loadingDatatable: 0,
        drawer : false,
        currentNetwork: "",
        form : {} as any,
        loaderEnabledTest : false,
        typeEddEdit : "",


    });

    const tableHeader = ref([
      { name: "ID", key: "us_seq", sortable: false},  
      { name: "Prénom Nom", key: "us_prenom", sortable: false},
      { name: "Utilisateur", key: "us_username", sortable: false,},
      { name: "Role", key: "us_role", sortable: false,},
      { name: "", key: "us_test", sortable: false,},
    ]);

      onMounted(async () => {  
        let nUsers = await getAxios("/users");
        state.myList = nUsers
        state.initialMyList = state.myList;
       
        state.loaderEnabled = false;

      });

      const addEdit_user = (data) => {
        if(data.us_seq == -1){
          state.typeEddEdit = "Ajout d'un utilisateur";

          state.form.us_seq = -1;
          state.form.us_prenom = "";
          state.form.us_nom = "";
          state.form.us_username = "";
          state.form.us_api_key_meraki = "";
          state.form.us_role = "USR";
          state.form.new_password = "";
          state.form.new_repassword = "";

        } else {
          state.typeEddEdit = "Modification d'un utilisateur";

          state.form.us_seq = data.us_seq;
          state.form.us_prenom = data.us_prenom;
          state.form.us_nom = data.us_nom;
          state.form.us_username = data.us_username;
          state.form.us_api_key_meraki = data.us_api_key_meraki;
          state.form.us_role = data.us_role;
          state.form.new_password = "";
          state.form.new_repassword = "";

        }
        state.drawer = true;
      };

      const test_api = async () => {
        if(!state.form.us_api_key_meraki) {
          ElNotification({
            title: 'Erreur',
            message: 'Veuillez saisir une clé Meraki, svp',
            type: 'error',
          })
          return false;
        }       
        state.loaderEnabledTest = true;
        const mOrga = await getAxios("/testapi?key="+state.form.us_api_key_meraki);
        state.loaderEnabledTest = false;
        if(mOrga.errors) {
          ElNotification({
            title: 'Erreur',
            message: 'Il semble y avoir une erreur avec la clé API saisie, veuillez la vérifier, svp',
            type: 'error',
          })
        } else {
          ElNotification({ title: 'Succés', message: 'La clé saisie semble fonctionnelle !', type: 'success',})
        }
      }

      const delUser = async () => {
        const form = JSON.stringify(state.form);
        await mAxiosApi.post("/delUser", form, { headers: {   'Content-Type': 'application/json'  }  });
        
        let nUsers = await getAxios("/users");
        state.myList = nUsers
        state.initialMyList = state.myList;
        state.loadingDatatable = state.loadingDatatable + 1;
        state.drawer = false;

      }

      const applyChange = async () => {

        if (state.form.us_seq == -1 && !state.form.new_password) {
          ElNotification({ title: 'Error', message: "Lors de la création d'un compte, le mot de passe doit être stipulé", type: 'error',})
          return false;
        }

        if (state.form.new_password && state.form.new_password != state.form.new_repassword) {
          ElNotification({ title: 'Error', message: "Les mots de passe saisis sont différents", type: 'error',})
          return false;
        }

        if (!state.form.us_username || !state.form.us_prenom || !state.form.us_nom){
          ElNotification({ title: 'Error', message: 'Un des champs obligatoire n\'est pas saisi', type: 'error',})
          return false;
        } 

        const regexMail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!regexMail.test(state.form.us_username)){
          ElNotification({ title: 'Error', message: 'Le mail de connexion doit être un mail valide', type: 'error',})
          return false;
        }

        const form = JSON.stringify(state.form);
        await mAxiosApi.post("/addEditUser", form, { headers: {   'Content-Type': 'application/json'  }  });
        ElNotification({ title: 'Succés', message: 'Changements effectués', type: 'success',})

        let nUsers = await getAxios("/users");
        state.myList = nUsers
        state.initialMyList = state.myList;

        state.loadingDatatable = state.loadingDatatable + 1;

        state.drawer = false
      }
  
      return {
        state,
        store,
        tableHeader,
        addEdit_user,
        test_api,
        applyChange,
        delUser
      };
    },
  });
  
  export const getAxios = async (rr) => { const re = await mAxiosApi.get(rr); return re.data; };

  